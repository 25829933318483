import React, { Component } from 'react'
import LivePerformance from '../components/LivePerformance'
import Layout from '../layouts/Layout'

export default class livePerformance extends Component {
  render() {
    return (
        <Layout>
            <LivePerformance />
        </Layout>
    )
  }
}
